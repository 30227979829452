<nz-table #basicTable [nzData]="shipments" nzFrontPagination="false" nzPaginationPosition = "top" nzSize="small">
    <thead>
        <tr>
            <th [nzChecked]="checked" [nzIndeterminate]="indeterminate" (nzCheckedChange)="onAllChecked($event)"></th>
            <th nzWidth="20px"></th>
            <th nzWidth="100px">Id</th>
            <th nzWidth="90px">Order</th>
            <th></th>
            <th></th>
            <th></th>
            <th>Pickup</th>
            <th></th>
            <th>Pickup Window</th>
            <th>Delivery</th>
            <th>Delivery Window</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of shipments" [class]="{'INVALID': data.issue, 'EXCLUDED': data.excluded, 'PLANNED': data.session}" (mouseenter)="hoverShipment.emit(data.id)" (mouseleave)="hoverShipment.emit(null)" (click)="clickShipment.emit(data.id)">
            <!-- <td>
                <span nz-icon nzType="delete" nzTheme="outline" class="clickable" (click)="removeShipment($event, data.id)"></span>
            </td> -->
            <td
                [nzChecked]="setOfCheckedId.has(data.id)"
                [nzDisabled]="data.excluded"
                (nzCheckedChange)="onItemChecked(data.id, $event)"
            ></td>
            <td>
                <span nz-dropdown [nzDropdownMenu]="menu">
                    <span nz-icon nzType="ellipsis" nzTheme="outline"></span>
                </span>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item (click)="removeShipment($event, data.id)">
                            <span nz-icon nzType="delete" nzTheme="outline" class="right5"></span> Remove
                        </li>
                        <li nz-menu-item (click)="updateShipmentReviewStatus($event, data)">
                            <span nz-icon nzType="file-done" nzTheme="outline" class="right5"></span> Update Review Status
                        </li>
                        <li nz-menu-item [nzDisabled]="data.issue" (click)="addToPlanning($event, data.id)">
                            <span nz-icon nzType="plus" nzTheme="outline" class="right5"></span>
                            Add to Planning
                        </li>
                    </ul>
                </nz-dropdown-menu>                        
            </td>
            <td>
                <i nz-icon nzType="loading" nzTheme="outline" *ngIf="data.addingStatus=='ADDING'"></i>
                <i nz-icon nzType="check" style="color: green;" nzTheme="outline" *ngIf="data.addingStatus=='ADDED'"></i>
                <i nz-icon nzType="warning" style="color: red;" nzTheme="outline" *ngIf="data.addingStatus=='ERROR'"></i>
                <span class="clickable right5" (click)="onCopyId($event, data.warpId)">{{ data.warpId }}</span>
                <a target="_blank" *ngIf="!data.orderId" [routerLink]="[routeAdminOrderList]" [queryParams]="{search: data.warpId}">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
                <a target="_blank" *ngIf="data.orderId" [routerLink]="[routeAdminOrderList, data.orderId]">
                    <span nz-icon nzType="link" nzTheme="outline"></span>
                </a>
            </td>
            <td>
                <span class="clickable right5" (click)="onCopyId($event, data.orderWarpId)">{{ data.orderWarpId || '' }}</span>
                <ng-container *ngIf="data.parentId">
                    <span nz-popover nzPopoverTitle="Transit Network" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate" nzPopoverPlacement="rightBottom">
                        <span style="color: #888;" nz-icon nzType="partition" nzTheme="outline"></span>
                    </span>
                    <ng-template #contentTemplate>
                        <div transit-network [id]="data.parentId" [viewOnly]="true"></div>
                    </ng-template>
                </ng-container>
            </td>
            <td>
                <span *ngIf="data.issue" style="color: red;" nz-icon nzType="warning" nzTheme="outline"
                    nz-popover nzPopoverTitle="Validation Errror" [nzPopoverContent]="data.issue"
                >
                </span>
                <!-- <span *ngIf="!data.issue" style="color: green;" nz-icon nzType="check" nzTheme="outline"></span> -->
            </td>
            <td>
                {{ data.transitType }}
            </td>
            <td>{{data.clientName}}</td>
            <td>
                <!-- <span *ngIf="data.pickup.market">[{{data.pickup.market}}]</span> -->
                {{ data.pickup.addr.state }}-{{ data.pickup.addr.zipcode }}
            </td>
            <td>
                <span *ngIf="data.readiness" [style.color]="readinessColors[data.readiness.level]" nz-icon nzType="like" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
                <span *ngIf="data.readiness && data.readiness.level === 0" style="color: red;" nz-icon nzType="dislike" nzTheme="outline" nz-tooltip [nzTooltipTitle]="data.readiness?.message"></span>
            </td>
            <td>
                <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.pickup)">
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                </span>
                {{data.pickupWindow}}
            </td>
            <td>
                <!-- <span *ngIf="data.dropoff.market">[{{data.dropoff.market}}]</span> -->
                {{ data.dropoff.addr.state }}-{{ data.dropoff.addr.zipcode }}
            </td>
            <td>
                <span class="clickable edit-button" (click)="onBtnEditWindowsTime(data, data.dropoff)">
                    <span nz-icon nzType="edit" nzTheme="outline"></span>
                </span>
                {{data.dropoffWindow}}
                <span *ngIf="data.dropoff.requiresAppointment && !data.dropoff.appointmentInfo?.from" style="color: red;">Appt required</span>
            </td>
        </tr>
    </tbody>
</nz-table>